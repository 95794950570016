import React from 'react'
import { Wrapper, ContentTop, ContentMid, Content } from './section.style'

function Section({ title, desc, backgroundImg, link, leftbtn, rightbtn, arrow, pp1, pp2, pp3, pp4, name }) {
    return (
        <Wrapper bg={backgroundImg} >
            <ContentTop>
                <h1>{title}</h1>
                <p>{desc} <a href='#'>{link}</a></p>
                {name && name == "contactus" && (
                    <div>
                        <p>{"A Bmado Global Alliance"} &reg; <a href='#'>{link}</a></p>
                        <b>+91 9995 74 74 75<a href='#'>{link}</a></b>
                    </div>
                )}
            </ContentTop>

            <div>
                <ContentMid className={arrow ? '' : 'buttons'} >
                    {leftbtn &&
                        <div className='left'>
                            <button>{leftbtn}</button>
                        </div>
                    }
                    {rightbtn &&
                        <div className='right'>
                            <button>{rightbtn}</button>
                        </div>
                    }
                </ContentMid>
                {pp1 &&
                    <Content>
                        <div className="Info-bar">
                            <div className="Specs" >
                                <h2>{pp1}</h2>
                            </div>
                            <div className="Specs" >
                                <h2>{pp2}</h2>
                            </div>
                            <div className="Specs" >
                                <h2>{pp3}</h2>
                            </div>
                            {pp4 &&
                                <div className="Specs hp" >
                                    <h2>{pp4}</h2>
                                </div>
                            }
                            <div className="order_btn">
                                <button> ORDER NOW </button>
                            </div>
                        </div>
                    </Content>
                }
                {arrow &&
                    <div className='arrow'>
                        <img src='/images/down-arrow.svg' alt='arrow' />
                    </div>
                }
            </div>
        </Wrapper>
    )
}

export default Section
