import React from 'react'
import { Wrapper, Content } from './home.style'

import Section from './Section'
import Header from './Header';
import Footer from './Footer';

function Home() {

    document.title = "Premium Luxuary & Non Luxuary Furnitures | Luxfur";
    return (
        <>
            <Header />
            <Wrapper>
                <Content>
                    <Section title="Royal Wooden Hand Carved Sofa" desc="Order Online for " link="Touchless Delivery" leftbtn="CUSTOM ORDER" rightbtn="EXISTING INVENTORY" backgroundImg="https://luxfur.blob.core.windows.net/home-images/sofa.jpg" arrow="true" />
                </Content>
                <Content>
                    <Section title="Antique Royal Classic Bed" desc="Order Online for " link="Touchless Delivery" leftbtn="CUSTOM ORDER" rightbtn="EXISTING INVENTORY" backgroundImg="https://luxfur.blob.core.windows.net/home-images/bed.jpg" />
                </Content>
                <Content>
                    <Section title="Luxuary Designed Chair" desc="Order Online for " link="Touchless Delivery" leftbtn="CUSTOM ORDER" rightbtn="EXISTING INVENTORY" backgroundImg="https://luxfur.blob.core.windows.net/home-images/chair.jpg" />
                </Content>
                <Content>
                    <Section title="Royal Collection Classic Office Desk" desc="Order Online for " link="Touchless Delivery" leftbtn="CUSTOM ORDER" rightbtn="EXISTING INVENTORY" backgroundImg="https://luxfur.blob.core.windows.net/home-images/table.jpg" />
                </Content>
                <Content>
                    <Section title="Wooden Rustic Decorative Articles" desc="Lowest Cost Solar Panels in America" leftbtn="ORDER NOW" rightbtn="LEARN MORE" backgroundImg="https://luxfur.blob.core.windows.net/home-images/accessories.jpg" />
                </Content>
                {/* <Content>
                    <Section title="Solar Roof" desc="Produce Clean Energy From Your Roof" leftbtn="ORDER NOW" rightbtn="LEARN MORE" backgroundImg="https://i.pinimg.com/564x/8f/9d/ad/8f9dad61563dc7e6fc2e600cdbaab3a5.jpg" />
                </Content> */}
                <Content>
                    {/* <Section title="Accessories" leftbtn="SHOP NOW" backgroundImg="accessories.jpg" /> */}
                    <Footer />
                </Content>
            </Wrapper>
        </>
    )
}

export default Home