import React from "react";

import { Wrapper, Content } from "./footer.syle";

const Footer = ({ bottom }) => {

    return (
        <Wrapper>
            <Content className={bottom} >
                <div>
                    <a style={{ color: "white" }} href="https://luxfur.in" target='_blank' >Bmado Global &copy; 2023</a>
                </div>
                <div>
                    {/* <a href="bmadoglobal@gmail.com" target='_blank'>Gmail</a> */}

                </div>
            </Content>
        </Wrapper>
    )
}

export default Footer