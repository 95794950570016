import React, { useState } from 'react'
import { Wrapper, Content, Content1 } from './header.style'
import { Link } from 'react-router-dom';

import SideNav from '../SideNav'
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <Wrapper>
                <div className='logo' >
                    <Link to='/'>
                        <img className='logoImage' src='https://luxfur.blob.core.windows.net/luxfur-logo/logo.png' alt='logo' />
                    </Link>
                </div>
                <Content>
                    <Link to='/bed'>Bed & Bedroom Set</Link>
                    <Link to='/living'>Living Room</Link>
                    <Link to='/dining'>Dining Room</Link>
                    <Link to='/handcarve'>Hand Carved Furniturs</Link>
                    <Link to='/allcollection'>All Collections</Link>
                    <Link to='/contactus'>Contact Us</Link>
                </Content>
                <Content1>
                    <a href='#' className='none' >Shop</a>
                    {/* <Link to='/login' className='none'>Account</Link> */}
                    <a href='#' onClick={() => setOpen(true)} >Menu</a>
                </Content1>
                {open &&
                    <div className='top'>
                        <div className='close' onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </div>
                    </div>
                }
                <SideNav show={open} />
            </Wrapper>
        </>
    )
}

export default Header