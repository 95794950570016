import React, { useEffect } from "react";
import AOS from 'aos'
import "aos/dist/aos.css";

import { Wrapper, Content } from "./interior.style";

const Interior = (props) => {

    useEffect(() => {
        AOS.init({ duration: 1400 });
    }, []);

    return (
        <Wrapper>
            <div className="title" >
                <h1 data-aos='fade-up' >{props.specTitle}</h1>
            </div>
            <Content bg={props.bg}>
            </Content>
        </Wrapper>
    )
}

export default Interior;