import React, { useEffect, useState } from "react";
import AOS from 'aos'
import "aos/dist/aos.css";

import { Wrapper, Content } from "./spec.style";

const Specs = (props) => {

    const [imageArr, setImageArr] = useState([]);


    useEffect(() => {
        AOS.init({ duration: 1400 });
        AOS.refresh();

        props.data.forEach(element => {
            if (element.name == props.name) {
                setImageArr(element);
            }
        });
    }, []);



    return (
        <Wrapper>
            <Content>
                <div className="row" >
                    <div className="col" data-aos='fade-right'>
                        {imageArr?.images?.small_1 && (
                            < img src={imageArr.images.small_1} alt="game" />
                        )}
                    </div>
                    <div className="col text" data-aos='fade-left'>
                        <h3> {imageArr.desc1Title} </h3>
                        <p>{imageArr.desc1}</p>
                    </div>
                </div>
                <div className="row" >
                    <div className="col text1" data-aos='fade-right'>
                        <h3> {imageArr.desc2Title} </h3>
                        <p>{imageArr.desc2}</p>

                    </div>
                    <div className="col" data-aos='fade-left'>
                        {imageArr?.images?.small_1 && (
                            < img src={imageArr.images.small_2} alt="game" />
                        )}
                    </div>
                </div>
                <div className="row" >
                    <div className="col" data-aos='fade-right'>
                        {imageArr?.images?.small_1 && (
                            < img src={imageArr.images.small_3} alt="game" />
                        )}
                    </div>
                    <div className="col text" data-aos='fade-left'>
                        <h3> {imageArr.desc3Title} </h3>
                        <p>{imageArr.desc3}</p>
                    </div>
                </div>

            </Content>
        </Wrapper>
    )
}

export default Specs;