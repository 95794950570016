import React, { useState, useEffect } from "react";

import { Wrapper } from "./model3.style";

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";
import data from "../../data/data";

const Living = () => {


    const [name] = useState("living");
    const [imageArr, setImageArr] = useState([]);


    useEffect(() => {

        data.forEach(element => {
            if (element.name == name) {
                setImageArr(element);
            }
        });
    }, []);



    document.title = "Model 3 | Tesla";
    return (
        <Wrapper>
            <Header />
            {imageArr?.images && (
                <Section
                    title={imageArr.title}
                    desc={imageArr.desc}
                    backgroundImg={imageArr?.images.large_main}
                    arrow="true"
                    pp1={imageArr.pp1}
                    pp2={imageArr.pp2}
                    pp3={imageArr.pp3}
                    pp4={imageArr.pp4}
                    name={name}
                />
            )}
            {imageArr?.images && (
                <Interior bg={imageArr?.images.large_sub} specTitle={imageArr?.specTitle} />
            )}
            <Specs data={data} name={name} />
            {/* <Footer bottom='bottom' /> */}
        </Wrapper>
    )
}

export default Living;