import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Using AOS for animation

// Components
import GlobalStyle from './globalstyles';
import Home from './components/home';
import Bed from './components/Bed';
import Living from './components/Living';
import Dining from './components/Dining';
import Handcarve from './components/Handcarve';
import Allcollection from './components/Allcollection';
import Contactus from './components/Contactus';
import Login from './components/Login';
import Signup from './components/Signup';
import Account from './components/Account';

import { useSelector, useDispatch } from 'react-redux';
import { selectUser, login, logout } from './features/userSlice';
import { auth } from './components/firebase';

function App() {

  const user = useSelector(selectUser)

  const dispatch = useDispatch()

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        // User is signed in
        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
          })
        )
      } else {
        // User is signed out
        dispatch(logout())
      }
    })
  }, [dispatch])

  return (
    <Router>
      <div className="App">
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path="/bed" element={<Bed />} />
          <Route path="/living" element={<Living />} />
          <Route path="/dining" element={<Dining />} />
          <Route path="/handcarve" element={<Handcarve />} />
          <Route path="/allcollection" element={<Allcollection />} />
          <Route path="/contactus" element={<Contactus />} />
          {/* <Route path="/signup" element={<Signup/>} />
          <Route 
            path="/teslaaccount" 
            element={
              <main>
                {user ? <Account/> : <Navigate to='/login'/>}
              </main>
            }
          />
          <Route 
            path="/login" 
            element={
              <main>
                {user ? <Navigate to='/teslaaccount'/> : <Login/>}
              </main>
            } 
          /> */}

        </Routes>

        <GlobalStyle />
      </div>
    </Router>
  );
}

export default App;