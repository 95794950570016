const data = [
    {
        name: "bed",
        images: {
            large_main: "https://luxfur.blob.core.windows.net/test/bed_large.jpg",
            large_sub: "https://luxfur.blob.core.windows.net/test/bed_large_1.jpg",
            small_1: "https://luxfur.blob.core.windows.net/test/bed_small_1.jpg",
            small_2: "https://luxfur.blob.core.windows.net/test/bed_small_2.jpg",
            small_3: "https://luxfur.blob.core.windows.net/test/bed_small_3.jpg"
        },
        title: "Luxurious Royal Bed - Fit For A King/Queen",
        desc: "Handcrafted with the finest materials and traditional techniques",
        pp1: "Versatile",
        pp2: "Well-Crafted",
        pp3: "Effortless",
        pp4: "Speed-Timeless",
        specTitle: "Antique Masterpiece Bedroom Set",
        desc1Title: "Inspired Royal Curved Bed",
        desc2Title: "Designed to fit specific spaces or dimensions",
        desc3Title: "Made to Your Space and Style",
        desc1: "Perfect choice for those who appreciate the vintage style but also want a piece of furniture that is functional and comfortable. Its curved design adds an unique and elegant touch to any bedroom, making it a perfect fit for a royal's chambers",
        desc2: "Furniture that is designed to fit specific spaces or dimensions using virtual reality technology allows you to visualize and customize their furniture to fit their specific needs and preferences",
        desc3: "Using Our virtual reality software provide you with an unprecedented level of control over their furniture purchase and the ability to see how the furniture will look and fit in their space before it is even made",

    }, {
        name: "chair",
        images: {
            large_main: "https://luxfur.blob.core.windows.net/test/bed_large.jpg",
            large_sub: "https://luxfur.blob.core.windows.net/test/bed_large_1.jpg",
            small_1: "https://luxfur.blob.core.windows.net/test/bed_small_1.jpg",
            small_2: "https://luxfur.blob.core.windows.net/test/bed_small_2.jpg",
            small_3: "https://luxfur.blob.core.windows.net/test/bed_small_3.jpg"
        },
        title: "Chairsss ",
        desc: "asdasasd",
        pp1: "",
        pp2: "",
        pp3: "",
        pp4: "",
        specTitle: "Antique Masterpiece Bedroom Set",
        desc1Title: "",
        desc2Title: "",
        desc3Title: "",
        desc1: "",
        desc2: "",
        desc3: "",

    },
    {
        name: "living",
        images: {
            large_main: "https://luxfur.blob.core.windows.net/test/living_large.jpg",
            large_sub: "https://luxfur.blob.core.windows.net/test/living_large1.jpg",
            small_1: "https://luxfur.blob.core.windows.net/test/living_small_1.jpg",
            small_2: "https://luxfur.blob.core.windows.net/test/living_small_2.jpg",
            small_3: "https://luxfur.blob.core.windows.net/test/living_small_3.jpg"
        },
        title: "Luxurious Royal Living Room Furniture",
        desc: "Exquisite craftsmanship and attention to detail",
        pp1: "Exquisite",
        pp2: "Comforting",
        pp3: "Versatile",
        pp4: "Sustainable ",
        specTitle: "Expertly Crafted Living Hall Interior",
        desc1Title: "Unique Design and Exceptional Craftsmanship",
        desc2Title: "Elegant Living Room Furniture Set",
        desc3Title: "Perfectly Built to Your Space and Style",
        desc1: "perfect for those who appreciate individuality and are looking for a piece that is not only functional but also adds an aesthetic appeal to the room. It is a perfect fit for those who are looking for something that is not only beautiful but also built to last, and that can be passed down through generations as an heirloom",
        desc2: "The best Collection of furniture pieces designed to complement each other and create a cohesive and beautiful living room. This type of furniture set typically includes a sofa, armchairs, coffee table, and other pieces that are chosen to match in terms of style, design, and material.  ",
        desc3: "We perfectly tailored to your space and style is custom-built specifically to fit your needs and preferences by using advanced technologies.",

    },
    {
        name: "dining",
        images: {
            large_main: "https://luxfur.blob.core.windows.net/test/dining_large.jpg",
            large_sub: "https://luxfur.blob.core.windows.net/test/dining_large1.jpg",
            small_1: "https://luxfur.blob.core.windows.net/test/dining_small_1.jpg",
            small_2: "https://luxfur.blob.core.windows.net/test/dining_small_2.jpg",
            small_3: "https://luxfur.blob.core.windows.net/test/dining_small_3.jpg"
        },
        title: "Elegant Dining Set",
        desc: "Stylish Mealtime Experience",
        pp1: "Durability",
        pp2: "Elegance",
        pp3: "Functionality",
        pp4: "Versatility",
        specTitle: "Crafted for Comfort and Style",
        desc1Title: "The centerpiece of your dining room",
        desc2Title: "Unique Statement Piece for Your Hall",
        desc3Title: "Exclusive Dining Set",
        desc1: "A beautifully designed and well-crafted dining table can add both style and functionality to a dining room, making it not only a functional space for eating but also a focal point for entertaining and gathering with friends and family. The best dining table is one that is not only well-built and durable, but also elegant, stylish and versatile to match any dining room decor",
        desc2: "A unique aspect of a dining room table and chairs set could be the design, material or features it has.Or the set could be handcrafted by a skilled artisan, adding a personal touch and uniqueness to the piece",
        desc3: "Add a touch of elegance and personality to your dining room with our one-of-a-kind furniture",

    }, {
        name: "handcarve",
        images: {
            large_main: "https://luxfur.blob.core.windows.net/test/handcarve_large.jpg",
            large_sub: "https://luxfur.blob.core.windows.net/test/handcarve_large_1.jpg",
            small_1: "https://luxfur.blob.core.windows.net/test/handcarve_small_1.jpg",
            small_2: "https://luxfur.blob.core.windows.net/test/handcarve_small_2.jpg",
            small_3: "https://luxfur.blob.core.windows.net/test/handcarve_small_3.jpg"
        },
        title: "A Masterpiece of Art in Your Dining Room",
        desc: "Vintage Elegance",
        pp1: "Authenticity",
        pp2: "Craftsmanship",
        pp3: "Elegance",
        pp4: "Timelessness",
        specTitle: "A Unique and Exquisite Addition to Your Dining Room",
        desc1Title: "Antique Charm",
        desc2Title: "Timeless Treasure",
        desc3Title: "Elegant Simplicity",
        desc1: "The unique design and craftsmanship of antique furniture can make them statement pieces in any room. They can add a touch of elegance and personality to a room and can serve as conversation starters as well as functional pieces of furniture. The charm of antique furniture is that it can be passed down from generation to generation, and it will always have a special place in any home",
        desc2: "An antique furniture piece can add a sense of history, character, and elegance to any room. These pieces are hand-crafted and have a rich history and patina that can make them stand out as one-of-a-kind, and give a room a distinctive and sophisticated feel",
        desc3: "Hand-carved antique furniture is a unique and exquisite addition to any room. These pieces are crafted by skilled artisans and have a rich history and patina that can add a sense of warmth and elegance to a space. The hand-carved details and unique design of these pieces can make them stand out as one-of-a-kind and give a room a distinctive and sophisticated feel.We make an additional feautures for understanding beauty of antique piece in your space.",

    },
    {
        name: "allcollection",
        images: {
            large_main: "https://luxfur.blob.core.windows.net/test/allcollection_large.jpg",
            large_sub: "https://luxfur.blob.core.windows.net/test/allcollection_large_1.jpg",
            small_1: "https://luxfur.blob.core.windows.net/test/allcollection_small_1.jpg",
            small_2: "https://luxfur.blob.core.windows.net/test/allcollection_small_2.jpg",
            small_3: "https://luxfur.blob.core.windows.net/test/allcollection_small_3.jpg"
        },
        title: "A Complete Furniture Set for a Distinctive Home",
        desc: "Grand Collection",
        pp1: "Elegance",
        pp2: "Distinctiveness",
        pp3: "Timelessness",
        pp4: "Complete",
        specTitle: "Best of the Best",
        desc1Title: "Your Choice",
        desc2Title: "Furnish Your Dreams",
        desc3Title: "Creating Your Haven",
        desc1: "When choosing the best furniture, first consider the purpose of the piece. What function will it serve in your home? This will help you determine the size, style, and features you need Second, consider the quality of the materials and construction. Look for furniture made with durable materials and solid construction to ensure it will last for a long time. You can make your the best choice by our help and advanced technologies.",
        desc2: "It implies that the furniture is stylish, comfortable, and of high quality, and that it can be used to create a space that is both functional and beautiful.It also implies that the selection is carefully curated and expertly chosen, to ensure that you are able to find the perfect pieces for their home, to match your visions and make your dream home a reality.",
        desc3: "The best selection can help you in creating a space that not just looks good but also feels good, providing a sense of comfort and relaxation",

    },
    {
        name: "contactus",
        title: "Luxfur Inc",
        desc: "Kerala India",
        pp1: "",
        pp2: "",
        pp3: "",
        pp4: "",

    }

]
export default data;