import styled from "styled-components";

export const Wrapper = styled.div`
    overflow-x: hidden;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: nowrap;

    a{
        padding: 7px 16px;
        font-size: 0.92rem;
        letter-spacing: 0.6px;
        text-decoration: none;
        font-weight: 500;
        border-radius: 10px;
        transition: background-color 200ms ease-in;
        cursor: pointer;
        :hover{
            background-color: rgba(90, 90, 90, 0.1);
        }
    }
    
    @media screen and (max-width: 1150px){
        display: none;
    }
`;