import React, { useState, useEffect } from "react";

import { Wrapper, Content } from "./allcollection.style";

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";


import data from "../../data/data";

const Allcollection = () => {

    const [name] = useState("allcollection");


    const [imageArr, setImageArr] = useState([]);


    useEffect(() => {

        data.forEach(element => {
            if (element.name == name) {
                setImageArr(element);
            }
        });
    }, []);


    document.title = "Bed Set | luxfur ";
    return (
        <Wrapper>
            <Header />
            <Content>
                {imageArr?.images && (
                    <Section
                        title={imageArr.title}
                        desc={imageArr.desc}
                        name={name}
                        backgroundImg={imageArr?.images.large_main}
                        arrow="true"
                        range='396'
                        speed='1.99'
                        top='200'
                        hp='1,020'
                    />
                )}
            </Content>
            <Content>
                {imageArr?.images && (
                    <Interior bg={imageArr?.images.large_sub} />
                )}
            </Content>
            <Content>
                <Specs data={data} name={name} />
            </Content>
            {/* <Footer bottom='bottom' /> */}
        </Wrapper>
    )
}

export default Allcollection; 