import React, { useState, useEffect } from "react";

import { Wrapper, Content } from "./contactus.styles";

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";

import data from "../../data/data";

const Contactus = () => {

    const [name] = useState("contactus");


    const [imageArr, setImageArr] = useState([]);


    useEffect(() => {

        data.forEach(element => {
            if (element.name == name) {
                setImageArr(element);
            }
        });
    }, []);


    document.title = "Dining X | Tesla";
    return (
        <Wrapper>
            <Header />
            <Section
                title={imageArr.title}
                desc={imageArr.desc}
                name={name}
                backgroundImg={"https://images.unsplash.com/photo-1499159058454-75067059248a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"}
                arrow="true"
                range='396'
                speed='1.99'
                top='200'
                hp='1,020'
            />


            <Footer bottom='bottom' />

        </Wrapper>
    )
}

export default Contactus;