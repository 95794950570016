import React from 'react'
import { Wrapper, Content } from './sidenav.style'

import { Link } from 'react-router-dom'

function SideNav({ show }) {

    return (
        <Wrapper show={show}>
            <Content>
                <Link to='/'><li className='half'>Home</li></Link>
                <Link to='/bed'><li className='half'>Bed Set</li></Link>
                <Link to='/living'><li className='half'>Living Room</li></Link>
                <Link to='/dining'><li className='half'>Dining</li></Link>
                <Link to='/handcarve'><li className='half'>Hand Curve</li></Link>
                <Link to='/allcollection'><li className='half'>All Collection</li></Link>
                <Link to='/contactus'><li className='half'>Conatct Us</li></Link>

                {/* <li className='half'>Solar Roof</li>
                <li className='half'>Solar Panels</li>
                <li>Existing Inventory</li>
                <li>Used Inventory</li>
                <li>Trade-In</li>
                <li>Cybertruck</li>
                <li>Roadster</li>
                <li>Semi</li>
                <li>Charging</li>
                <li>Powerwall</li>
                <li>Commercial Energy</li>
                <li>Utilities</li>
                <li>Find Us</li>
                <li>Support</li>
                <li>Investor Relations</li> */}
                <li className='half' >Shop</li>
                {/* <Link to='/login'><li className='half'>Account</li></Link> */}
                <li className='half'>More</li>
            </Content>
        </Wrapper>
    )
}

export default SideNav